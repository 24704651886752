import React, { useEffect } from 'react';
import './Support.css';

const Privacy = () => {
    useEffect(() => {
        document.title = 'Support';
    });
    return (
        <div className="Support">
            <h1>Support</h1>
            <p className="Paragraph">
                <p>Pour toute information et aide, contactez : hello@meltapp.co</p>
            </p>
        </div>
    );
};
export default Privacy;