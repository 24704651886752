import React from "react";
import Webpages from './webpages';

// http://local.meltapp.co:8080/


function App() {
  return (
      <div>
        <Webpages />
      </div>
  );
}
export default App;