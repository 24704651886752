//file: src/webpages/index.js
import React from 'react';
import {
    BrowserRouter as Router,
    Route,
} from "react-router-dom";
import Home from './Home';
import Privacy from './Privacy';
import Support from './Support';


const Webpages = () => {

    return(
        <Router>
            <Route exact path="/" component= {Home} />
            <Route path = "/privacy" component = {Privacy} />
            <Route path = "/support" component = {Support} />
        </Router>
    );
};
export default Webpages;