import './Home.css';
import React from "react";
import { isMobile, isAndroid, isIOS } from 'react-device-detect';

function iosUrl() {
    const queryParameters = new URLSearchParams(window.location.search);
    const utm_source = queryParameters.get("utm_source")?queryParameters.get("utm_source"):"";
    const utm_medium = queryParameters.get("utm_medium")?queryParameters.get("utm_medium"):"";
    const utm_campaign = queryParameters.get("utm_campaign")?queryParameters.get("utm_campaign"):"";
    const pt = utm_source+"_"+utm_medium;
    const ct = utm_campaign;
    const hasCampaignParams = queryParameters.get("utm_source") != null;

    let iosTrackingParams = "";
    if(hasCampaignParams) {
        iosTrackingParams = "?pt="+pt+"&ct="+ct;
    }
    return "https://apps.apple.com/fr/app/melt/id1589967641"+iosTrackingParams;
}

function androidUrl() {
    const queryParameters = new URLSearchParams(window.location.search);
    const utm_source = queryParameters.get("utm_source")?queryParameters.get("utm_source"):"";
    const utm_medium = queryParameters.get("utm_medium")?queryParameters.get("utm_medium"):"";
    const utm_campaign = queryParameters.get("utm_campaign")?queryParameters.get("utm_campaign"):"";
    const utm_term = queryParameters.get("utm_term")?queryParameters.get("utm_term"):"";
    const utm_content = queryParameters.get("utm_content")?queryParameters.get("utm_content"):"";
    const hasCampaignParams = queryParameters.get("utm_source") != null;

    let androidTrackingParams = "";
    if(hasCampaignParams) {
        androidTrackingParams = "&utm_source="+utm_source+"&utm_medium="+utm_medium+"&utm_campaign="+utm_campaign+"&utm_term="+utm_term+"&utm_content="+utm_content;
    }
    return "https://play.google.com/store/apps/details?id=co.meltapp.melt"+androidTrackingParams
}

function redirectOnAppStoreIfPossible() {
    if(isIOS) {
        window.location.replace(iosUrl());
    } else if(isAndroid) {
        window.location.replace(androidUrl());
    } else {

    }
}

function Home() {
    return (
        <div className="App">
            { redirectOnAppStoreIfPossible() }
            <img src="/logo_name_h.png" className="App-logo" alt="logo" />
            <p className="baseline">
                1er réseau social pour faire des rencontres
            </p>
            <p className="stores">
                <a target="_blank" rel="noreferrer" className="link-ios"  href={iosUrl()}>
                    <img src="/app_store_ios.png" className="store-ios" alt="store-ios" />
                </a>
                <a target="_blank" rel="noreferrer"  className="link-android" href={androidUrl()}>
                    <img src="/app_store_android.png" className="store-android" alt="store-android" />
                </a>
            </p>
            <p className="baseline2">
            </p>
            { false && <p className="footer">White Rabbit Studio SAS - RCS Paris B 881 592 208</p> }
        </div>
    );
}

export default Home;
