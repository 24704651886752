//file: src/webpages/Privacy.js
import React, { useEffect } from 'react';
import './Privacy.css';

const Privacy = () => {
    useEffect(() => {
        document.title = 'Privacy Policy';
    });
    return (
        <div className="Privacy">
            <h1>Confidentialité</h1>
            <h2>Informations collectées</h2>
            <p className="Paragraph">
                <p>Melt peut collecter les informations suivantes : prénom, date de naissance, intérêt pour un ou des genre(s),
                    intérêt pour une tranche d'âge, photos, géolocalisation de votre appareil, questions posées, réponses données, likes, "matchs", affinités, discussions.
                </p>
                <p>Ces informations sont liées à votre identifiant et sont nécessaires au fonctionnement de l'application.</p>
            </p>
            <p className="Paragraph">
                <p>Melt peut également recueillir des informations sur l'utilisation de l'application et d'éventuels crashs.</p>
                <p>Ces informations ne sont pas liées à votre profil</p>
            </p>
            <p className="Paragraph">
                <p>Melt peut transmettre l'ensemble de ces informations à des partenaires dans l'intérêt de fournir et améliorer le service.
                    L'utilisateur peut demander la suppression de ses données via le bouton "Supprimer mon compte" dans les paramètres du compte utilisateur.</p>
            </p>
            <p className="Paragraph">White Rabbit Studio SAS - RCS Paris B 881 592 208</p>
        </div>
    );
};
export default Privacy;