import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ReactGA from "react-ga4";
import {
    browserName,
    deviceType,
    isAndroid,
    isIOS,
    isMobile,
    mobileModel,
    mobileVendor,
    osVersion
} from "react-device-detect";

/*
Code was used before installing Google Tag Manager
ReactGA.initialize([
    {
        trackingId: "G-T4Z8HQ16CB",
        //gaOptions: {...}, // optional
        gtagOptions: {
            isMobile: isMobile,
            mobilePlatform: (isIOS)?"IOS":((isAndroid)?"Android":""),
            deviceType: deviceType,
            browserName: browserName,
            mobileVendor: mobileVendor,
            mobileModel: mobileModel,
            osVersion: osVersion
        }, // optional
    }
]);

 */

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);
/*
const SendAnalytics = ()=> {
    ReactGA.send({
        hitType: "pageview",
        page: window.location.pathname,
    });
}
 */

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log);
